import axios from "axios"

const baseUrl = "https://ishop-brangate.azurewebsites.net/api/llama"

// upload pdf to server
export const uploadPdfToServer = async (files, fileID, setSuggestions) => {
    const formData = new FormData()
    for (const file of files) {
        formData.append("file", file)
    }
    formData.append("fileID", fileID)
    const res = await axios.post(`${baseUrl}/upload`, formData)
    const result = res.data
    setSuggestions(result.suggestedQuestions)
}

// send question to server and return the server response
export const askQuestions = async (filename, val) => {
    const res = await axios.post(`${baseUrl}/query`, {
        question: val,
        filename: filename
    }, {validateStatus: ()=> true})
    if (res.status !== 200) {
        return null
    }
    const response = res.data
    return response.content
}

export const fetchMoreQuestions = async (id) => {
    const res = await axios.post(`${baseUrl}/questions`, {
        filename: id
    }, {validateStatus: ()=> true})
    if (res.status !== 200) {
        return null
    }
    const response = res.data
    return response.suggestedQuestions
}