import { toast } from "react-toastify"

// show a warning when user attempt typing without uploading a document first
export const promptFileUpload = (ev) => {
    if (ev.target.readOnly) {
        toast.warning("Please Upload A Document First")
    }
}

const acceptedFormat = ["pdf", "doc", "docx", "txt", "csv"]

// check if the uploaded file is of pdf format
export const checkFileFormat = (ev) => {
    for (const file of ev.target.files) {
        if (!acceptedFormat.includes(file.name.split('.').pop().toLowerCase())) {
            return false
        }
    }
    return true
}