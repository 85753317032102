import "./Header.css"
import logo from "../img/logo.svg"
import add from "../img/add.svg"
import questionmark from "../img/question-mark.png"
import fileImg from "../img/file.svg"
import { checkFileFormat } from "../utils/helper"
import { toast } from "react-toastify"
import { uploadPdfToServer } from "../api/api"
import { v4 as uuidv4 } from 'uuid';

const Header = ({ setFile, file, setShowModal, setID, setMessages, setSuggestions }) => {

    // this function check the uploaded file format and the upload it to the server
    const uploadFile = async (ev) => {
        if (ev.target.files.length < 1) {
            return
        }
        if (checkFileFormat(ev)) {
            const uniqueId = uuidv4();
            await toast.promise(uploadPdfToServer(ev.target.files, uniqueId, setSuggestions), {
                pending: "Loading please wait",
                success: "PDF uploaded successfully",
                error: "Error uploading PDF"
            })
            setID(uniqueId)
            setMessages([])
            if (ev.target.files.length > 1) {
                localStorage.setItem("filename", `${ev.target.files.length} documents`)
                setFile({name: `${ev.target.files.length} documents`})
            } else {
                setFile(ev.target.files[0])
                localStorage.setItem("filename", ev.target.files[0].name)
            }
            localStorage.removeItem("chats")
            localStorage.setItem("id", uniqueId)
        } else {
            toast.warning("Only pdf, doc, txt and csv format accepted")
        }
    }

    return (
        <div className="mainHeader">
            <img src={logo} alt="" />
            <img onClick={() => setShowModal(true)} style={{ cursor: "pointer" }} src={questionmark} alt="" />
            <div>
                {
                    file &&
                    <div>
                        <img src={fileImg} alt="" />
                        <p>{file?.name}</p>
                    </div>
                }
                <input onChange={uploadFile} type="file" name="pdf" id="pdf" hidden accept=".pdf, .doc, .docx, .csv, .txt" multiple />
                <label htmlFor="pdf">
                    <img src={add} alt="" />
                    <p>Upload Documents</p>
                </label>
            </div>
        </div>
    )
}

export default Header