import "./Input.css"
import { toast } from "react-toastify"
import send from "../img/send.svg"
import { promptFileUpload } from "../utils/helper"
import { askQuestions } from "../api/api"

const Input = ({ file, setMessages, messages, setLoad, id, reset }) => {

    // this function add the user questions and the server response to the message array
    const getMessages = async () => {
        const val = document.getElementById("message").value
        if (val.length <= 0) {
            toast.warning("Type in the input field")
            return
        }
        if (!file) {
            toast.warning("Please Upload A Document First")
            return
        }
        document.getElementById("down").style.height = "100px"
        document.getElementById("down").scrollIntoView()
        setLoad(true)
        const arr = [...messages]
        arr.push({
            sender: "user",
            message: document.getElementById("message").value
        })
        setMessages(arr)
        document.getElementById("down").scrollIntoView()
        // get response from server
        const response = await askQuestions(id, val)
        if (response) {
            setLoad(false)
            setMessages([...arr, {
                sender: "ai",
                message: response
            }])
            document.getElementById("message").value = ""
            document.getElementById("down").scrollIntoView()
            localStorage.setItem("chats", JSON.stringify([...arr, {
                sender: "ai",
                message: response
            }]))
            document.getElementById("down").style.height = "10px"
        } else {
            reset()
        }
    }

    const getMessageOnEnter = (e) => {
        if (e.key === 'Enter') {
            getMessages()
        }
    }

    return (
        <div className={file ? "inputMain enabled" : "inputMain"}>
            <input onKeyUp={getMessageOnEnter} id="message" onClick={promptFileUpload} readOnly={file ? false : true} placeholder={file ? "Ask a question..." : "Upload a PDF"} type="text" />
            <img style={{ cursor: file ? "pointer" : "not-allowed" }} onClick={getMessages} src={send} alt="" />
        </div>
    )
}

export default Input
