import "./Chat.css"
import user from "../img/user.svg"
import ai from "../img/dp.svg"
import add from "../img/add.svg"
import loadinggif from "../img/loading.gif"
import { askQuestions, fetchMoreQuestions } from "../api/api"

// both user messages and server responses are displayed here
const Chat = ({ messages, load, file, suggestions, setSuggestions, id, setLoad, setMessages, reset }) => {

    const removeSuggestion = (val) => {
        setSuggestions(suggestions.filter((suggestion) => suggestion !== val))
    }

    const getSuggestionAnswer = async (val) => {
        document.getElementById("down").style.height = "100px"
        document.getElementById("down").scrollIntoView()
        setLoad(true)
        const arr = [...messages]
        arr.push({
            sender: "user",
            message: val
        })
        setMessages(arr)
        document.getElementById("down").scrollIntoView()
        const response = await askQuestions(id, val)
        if (response) {
            removeSuggestion(val)
            setLoad(false)
            setMessages([...arr, {
                sender: "ai",
                message: response
            }])
            document.getElementById("message").value = ""
            document.getElementById("down").scrollIntoView()
            localStorage.setItem("chats", JSON.stringify([...arr, {
                sender: "ai",
                message: response
            }]))
            document.getElementById("down").style.height = "10px"
            if (suggestions.length === 1) {
                const moreQuestions = await fetchMoreQuestions(id)
                setSuggestions(moreQuestions)
            }
        } else {
            reset()
        }
    }

    return (
        <div className="chatMain">
            {
                !file ?
                    <div className="noPdf">
                        <label htmlFor="pdf">
                            <img src={add} alt="" />
                            <p>Upload Documents</p>
                        </label>
                        <div>
                            <h6>How do i use this website?</h6>
                            <p>Easily upload multiple files in various formats, including PDF, DOC, DOCX, CSV, and TXT, and ask questions based on the content!</p>
                        </div>
                        <div>
                            <h6>What can i use this site for?</h6>
                            <p>Whether it's a report, academic paper, or any other document, our platform enables you to quickly extract insights and get answers to specific questions.</p>
                        </div>
                        <div>
                            <h6>How many files can i upload?</h6>
                            <p>You can upload as many files as you want.</p>
                        </div>
                        <div>
                            <h6>Which document format is supported?</h6>
                            <p>We support PDF, DOC, DOCX, CSV, and TXT.</p>
                        </div>
                        <div>
                            <h6>Is this site totally free?</h6>
                            <p>Yes it is totally free, we do not charge you for using it.</p>
                        </div>
                    </div>
                    :
                    <>
                        {
                            messages?.map((message, i) => (
                                <div style={{ alignItems: "flex-start", marginBottom: "40px" }} key={i}>
                                    <img src={message.sender === "user" ? user : ai} alt="" />
                                    <p style={{ whiteSpace: "pre-line", lineHeight: "30px", marginTop: "0px" }}>{message.message}</p>
                                </div>
                            ))
                        }
                        {load && <div style={{ display: "flex", justifyContent: "center" }}><img style={{ width: "50px", height: "50px" }} src={loadinggif} alt="" /></div>}
                        <div style={{ width: "100%", height: "10px" }} id="down"></div>
                        <div className="suggested">
                            {
                                suggestions?.map((suggestion) => (
                                    <p><span onClick={() => getSuggestionAnswer(suggestion)}>{suggestion}</span> <span onClick={() => removeSuggestion(suggestion)} style={{ fontSize: "20px" }}>&times;</span></p>
                                ))
                            }
                        </div>
                    </>
            }
        </div>
    )
}

export default Chat